<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../service/MethodService";
import { FormInstance } from "element-plus";
import Validate from "../../service/ValidService";
const ruleFormRef = ref(FormInstance);
import toastr from "toastr";
import Data from "./dataForm-cau-hinh-tram";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { Filter } from "mushroomjs";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toUpperCase());
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const listLanguage = reactive({
  value: [],
});
const notDefineLanguages = ref([
  {
    code: "en",
    value: "",
  },
  {
    code: "vi",
    value: "",
  },
]);
const selectedToAddLang = ref("");
const errorChooseLang = ref(true);

const validateFlag = ref(false);
// const errorChooseLang = ref(true);

const optionsAlign = MethodService.copyObject(Data.optionsAlign);

const rulesForm = reactive({
  code: [Validate.required, Validate.checkCode],
  title: [Validate.required],
  index: [Validate.required, Validate.checkNumber],
  format: {
    width: [Validate.checkNoSpace, Validate.number0100, Validate.required],
    align: [
      {
        trigger: "change",
        required: true,
        get message() {
          return LanguageService?.lang?.["t_select_align"] ?? "t_select_align";
        },
      },
    ],
  },
});
// thêm mới cấu hình probe
const addNewSettingProbe = (formEl) => {
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    const checkMultiLangEmpty = listLanguage.value.some(
      (item) => item.error === true
    );
    if (valid && !checkMultiLangEmpty) {
      const probe_column_config_object = {
        code: dataForm.value.code,
        title: dataForm.value.title,
        title_lang: dataForm.value.title_lang,
        index: dataForm.value.index,
        permission: {
          view: dataForm.value?.permission?.view,
          edit: dataForm.value.permission.edit,
          filter: dataForm.value.permission.filter,
          sort: dataForm.value.permission.sort,
        },
        format: {
          width: dataForm.value.format.width,
          align: dataForm.value.format.align,
        },
      };
      try {
        const newId = await mushroom.probe_column_config.createAsync(
          probe_column_config_object
        );
        if (newId) {
          toastr.success(t("toastr_add_probe_config_success"));
          router.push({
            name: "DanhSachProbeColumn",
          });
        }
      } catch (e) {
        if (e.meta.field === "$unique_key") {
          toastr.error(t("toastr_code_exsited"));
        } else MethodService.showError(e.code);
      }
    } else {
      toastr.warning(t("toastr_check_input_again"));
      return false;
    }
  });
};

// cập nhật cấu hình probe
const updateSettingProbe = (formEl) => {
  validateFlag.value = true;
  formEl.validate(async (valid) => {
    const checkMultiLangEmpty = listLanguage.value.some(
      (item) => item.error === true
    );
    if (valid && !checkMultiLangEmpty) {
      console.log(dataForm.value);
      const probe_column_config_object = {
        id: route.params.id,
        code: dataForm.value.code,
        title: dataForm.value.title,
        title_lang: dataForm.value.title_lang,
        index: dataForm.value.index,
        permission: {
          view: dataForm.value.permission.view,
          edit: dataForm.value.permission.edit,
          filter: dataForm.value.permission.filter,
          sort: dataForm.value.permission.sort,
        },
        format: {
          width: dataForm.value.format.width,
          align: dataForm.value.format.align,
        },
      };
      try {
        const response = await mushroom.probe_column_config.partialUpdateAsync(
          probe_column_config_object
        );
        if (response.result == route.params.id) {
          toastr.success(t("toastr_edit_probe_config_success"));
          router.push({
            name: "DanhSachProbeColumn",
          });
        } else toastr.warning(t("toastr_probe_config_not_change"));
      } catch (e) {
        if (e.meta.field === "$unique_key") {
          toastr.error(t("toastr_code_exsited"));
        } else MethodService.showError(e.code);
      }
    } else {
      toastr.warning(t("toastr_check_input_again"));
      return false;
    }
  });
};

const fn_LayChiTietProbeColumn = async () => {
  try {
    const response = await mushroom.probe_column_config.listAsync({
      filters: Filter.eq("id", route.params.id).build(),
    });
    console.log("Thông tin probe_column_config trả về: %o", response);
    dataForm.value = response.result[0];
    if (response.result[0].title_lang) {
      let langObject = response.result[0].title_lang;
      let languageList = Object.keys(langObject);
      languageList.forEach((langCode) => {
        if (langObject[langCode] !== "")
          listLanguage.value.push({
            code: langCode,
            value: langObject[langCode],
          });
      });
      let notEmptyLang = listLanguage.value.map((item) => item.code);
      //Lấy ra danh sách các ngôn ngữ chưa được thực hiện đa ngôn ngữ
      let tmpNotDefineLanguage = [...notDefineLanguages.value];
      notDefineLanguages.value = tmpNotDefineLanguage.filter(
        (item) => !notEmptyLang.includes(item.code)
      );
    } else {
      dataForm.value = {
        ...dataForm.value,
        title_lang: {
          vi: "",
          en: "",
        },
      };
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const addLanguageInput = () => {
  if (!selectedToAddLang.value) {
    errorChooseLang.value = false;
    return;
  }
  errorChooseLang.value = true;
  listLanguage.value.push({
    code: selectedToAddLang.value,
    value: "",
  });
  notDefineLanguages.value = notDefineLanguages.value.filter(
    (item) => item.code !== selectedToAddLang.value
  );
  selectedToAddLang.value = "";
};

const removeLanguageMean = (code) => {
  listLanguage.value = listLanguage.value.filter((item) => item.code !== code);
  notDefineLanguages.value.push({
    code: code,
    value: "",
  });
  dataForm.value.title_lang[code] = "";
};

const saveLanguageDefine = (code) => {
  dataForm.value.title_lang[code] = removeSpace(
    dataForm.value.title_lang[code]
  );
  listLanguage.value = listLanguage.value.map((item) => {
    if (item.code === code) {
      if (removeSpace(dataForm.value.title_lang[code]) === "")
        return {
          ...item,
          error: true,
        };
      return {
        ...item,
        value: removeSpace(dataForm.value.title_lang[code]),
        error: false,
      };
    }
    return item;
  });
  console.log(dataForm.value.title_lang);
};

const removeSpace = (str) => {
  return str.replace(/\s+/g, " ").trim();
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    optionsAlign.value = MethodService.copyObject(Data.optionsAlign);
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value === true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(() => {
  if (
    route.name === "ChiTietCauHinhTram" ||
    route.name === "CapNhatCauHinhTram"
  ) {
    fn_LayChiTietProbeColumn();
  }
  let activeMenu = document
    .querySelector("#navbar-nav")
    .querySelector('[href="' + "/probe/setting-probe" + '"]');
  activeMenu?.classList.add("active");
  let parentCollapseDiv = activeMenu?.closest(".collapse.menu-dropdown");
  if (parentCollapseDiv) {
    parentCollapseDiv.classList.add("show");
    parentCollapseDiv.parentElement.children[0].classList.add("active");
    parentCollapseDiv.parentElement.children[0].setAttribute(
      "aria-expanded",
      "true"
    );
    if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
      parentCollapseDiv.parentElement
        .closest(".collapse")
        .classList.add("show");
      if (
        parentCollapseDiv.parentElement.closest(".collapse")
          .previousElementSibling
      )
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .previousElementSibling.classList.add("active");
    }
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="route.name === 'ThemMoiCauHinhTram'">
          {{ $t("t-add-setting-probe") }}
        </h3>
        <h3 v-if="route.name === 'ChiTietCauHinhTram'">
          {{ $t("t-details-setting-probe") }}
        </h3>
        <h3 v-if="route.name === 'CapNhatCauHinhTram'">
          {{ $t("t-update-setting-probe") }}
        </h3>
      </div>
      <div class="card-body">
        <el-form
          ref="ruleFormRef"
          :model="dataForm.value"
          status-icon
          :rules="rulesForm"
          label-width="200px"
          class="form-user"
        >
          <el-form-item :label="$t('t-code')" prop="code">
            <el-input
              type="text"
              autocomplete="off"
              v-model="dataForm.value.code"
              :placeholder="$t('t-place-code')"
              :disabled="
                route.name == 'CapNhatCauHinhTram' ||
                route.name == 'ChiTietCauHinhTram'
              "
            />
          </el-form-item>
          <el-form-item :label="$t('t-title')" prop="title">
            <el-input
              type="text"
              autocomplete="off"
              v-model="dataForm.value.title"
              :placeholder="$t('t-place-title')"
              :disabled="route.name == 'ChiTietCauHinhTram'"
              @change="dataForm.value.title = dataForm.value.title.trim()"
            />
          </el-form-item>
          <el-form-item label="">
            <div
              class="multi-language d-flex"
              :class="
                route.name == 'ChiTietCauHinhTram'
                  ? 'disabled_bg disabled_text'
                  : ''
              "
            >
              <div
                class="title"
                :class="
                  route.name == 'ChiTietCauHinhTram' ? 'disabled_text' : ''
                "
              >
                {{ $t("multiple_language") }}
              </div>
              <div style="flex-grow: 1">
                <div>
                  <div
                    v-for="item in listLanguage.value"
                    :key="item.code"
                    class="laguage_item"
                  >
                    <div class="input-language-label">{{ $t(item.code) }}</div>
                    <div class="input-language">
                      <el-input
                        v-model="dataForm.value.title_lang[item.code]"
                        :class="item?.error ? 'error_number' : ''"
                        @input="
                          removeSpace(dataForm.value.title_lang[item.code])
                        "
                        @blur="saveLanguageDefine(item.code)"
                        :disabled="route.name == 'ChiTietCauHinhTram'"
                        :placeholder="`${$t(
                          't_place_title_lang_of_column'
                        )} ${$t(item.code).toLowerCase()}`"
                      ></el-input>
                      <div
                        v-if="item?.error"
                        class="error"
                        style="text-align: left"
                      >
                        {{ $t("valid_required") }}
                      </div>
                    </div>
                    <el-tooltip :content="$t('t-delete')" placement="top">
                      <el-button
                        type="danger"
                        plain
                        @click="removeLanguageMean(item.code)"
                        v-if="!(route.name == 'ChiTietCauHinhTram')"
                        ><i class="ri-close-fill"></i
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div
                  class="d-flex"
                  v-if="
                    !(route.name == 'ChiTietCauHinhTram') &&
                    !notDefineLanguages.length == 0
                  "
                >
                  <div class="select-language">
                    <el-form-item label="">
                      <el-select
                        v-model="selectedToAddLang"
                        style="width: 200px"
                        :placeholder="$t('t-choose-lang')"
                      >
                        <el-option
                          v-for="item in notDefineLanguages"
                          :key="item.code"
                          :value="item.code"
                          :label="$t(item.code)"
                        />
                      </el-select>
                      <div
                        v-if="!errorChooseLang"
                        class="error"
                        style="text-align: left"
                      >
                        {{ $t("t-please-choose-lang") }}
                      </div>
                    </el-form-item>
                  </div>
                  <el-button
                    type="primary"
                    plain
                    style="padding: 8px"
                    @click="addLanguageInput"
                    ><i class="ri-add-circle-line"></i
                  ></el-button>
                </div>
                <div v-else class="text-center">
                  <span v-if="notDefineLanguages.length === 2">{{
                    $t("not_setup_multiple_language")
                  }}</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('t-index')" prop="index">
            <el-input
              type="text"
              autocomplete="off"
              v-model="dataForm.value.index"
              :placeholder="$t('t-place-index')"
              :disabled="route.name == 'ChiTietCauHinhTram'"
              @change="dataForm.value.index = dataForm.value.index.trim()"
            />
          </el-form-item>
          <el-form-item :label="$t('t-user-role-on-column')">
            <el-checkbox
              v-model="dataForm.value.permission.view"
              label="View"
              size="large"
              :disabled="route.name == 'ChiTietCauHinhTram'"
            />
            <el-checkbox
              v-model="dataForm.value.permission.edit"
              label="Edit"
              size="large"
              :disabled="route.name == 'ChiTietCauHinhTram'"
            />
            <el-checkbox
              v-model="dataForm.value.permission.filter"
              label="Filter"
              size="large"
              :disabled="route.name == 'ChiTietCauHinhTram'"
            />
            <el-checkbox
              v-model="dataForm.value.permission.sort"
              label="Sort"
              size="large"
              :disabled="route.name == 'ChiTietCauHinhTram'"
            />
          </el-form-item>
          <b-row>
            <b-col md="6">
              <el-form-item prop="format.width" :label="$t('t-width-column')">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="dataForm.value.format.width"
                  :placeholder="$t('t-place-width-column')"
                  :disabled="route.name == 'ChiTietCauHinhTram'"
                />
              </el-form-item>
            </b-col>
            <b-col md="6">
              <el-form-item :label="$t('t-align-column')" prop="format.align">
                <el-select
                  v-model="dataForm.value.format.align"
                  :placeholder="$t('t_choose_algin')"
                  :disabled="route.name == 'ChiTietCauHinhTram'"
                >
                  <el-option
                    v-for="item in optionsAlign"
                    :key="item.value"
                    :label="item.label[lang.toLowerCase()]"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </b-col>
          </b-row>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-soft-secondary btn-border mx-3"
              @click="router.go(-1)"
            >
              {{ $t("t-back") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-border"
              @click="addNewSettingProbe(ruleFormRef)"
              v-if="route.name === 'ThemMoiCauHinhTram'"
            >
              {{ $t("t-add") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-border"
              @click="updateSettingProbe(ruleFormRef)"
              v-if="route.name === 'CapNhatCauHinhTram'"
            >
              {{ $t("t-update") }}
            </button>
          </div>
        </el-form>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.map-container {
  position: relative;
  height: 500px;
}
.map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 40px;
  color: rgb(255, 95, 95);
}
:deep .form-user {
  .multi-language {
    padding: 20px;
    border: #f3f3f9 2px dotted;
    border-radius: 5px;
    .title {
      font-weight: 500;
      margin-right: 20px;
      color: #606266;
    }
    .select-language {
      margin-right: 10px;
    }
    .laguage_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .input-language-label {
        width: 12%;
        text-align: right;
      }
      .input-language {
        flex-grow: 1;
        margin: 0 10px;
      }
      .el-button {
        padding: 8px;
      }
    }
  }
  .disabled_text {
    color: var(--el-disabled-text-color) !important;
  }
  .disabled_bg {
    background-color: #f5f7fa;
  }
  .error_number {
    .el-input__wrapper {
      color: red;
      box-shadow: 0 0 0 1px #f56c6c inset;
    }
  }
  .error {
    color: var(--el-color-danger);
    font-size: 12px;
  }
}
</style>
